import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionOverview from '../../components/home/SectionOverview/SectionOverview';
import SectionFeaturedEvents from '../../components/home/SectionFeaturedEvents/SectionFeaturedEvents';
// import SectionSchedule from '../../components/home/SectionSchedule/SectionSchedule';
import SectionSponsors from '../../components/home/SectionSponsors/SectionSponsors';
import SectionCTA from '../../components/home/SectionCTA/SectionCTA';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Tech for Innovation - 2025 Alibabacloud Developer Summit</title>
            </Helmet>
            <Layout>
                <Hero />
                <div style={{ background: '#03062d' }}>
                    <SectionOverview />
                    <SectionFeaturedEvents />
                </div>
                {/* <SectionSchedule /> */}
                <div style={{ background: '#03062d' }}>
                    <SectionSponsors />
                    <SectionCTA />
                </div>
            </Layout>
        </>
    );
};

export default Home;
