// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import CardFeaturedEvent from './CardFeaturedEvent';

const SectionFeaturedEvents = () => {
    const events = [
        {
            id: 1,
            title: 'Keynote Speeches',
            description: 'Dapatkan inspirasi dari para ahli yang belajar tentang teknologi yang sedang tren di bidang AI.',
            thumbnail: 'https://img.alicdn.com/imgextra/i1/O1CN01ifBkll291ibqQ22bC_!!6000000008008-2-tps-1080-1600.png_.webp',
        },
        {
            id: 2,
            title: 'Products & Programs Launch',
            description: 'Dapatkan pembaruan terkini tentang rangkaian produk AI dan cloud kami yang terus berkembang.',
            thumbnail: 'https://img.alicdn.com/imgextra/i2/O1CN01ZCk7uc26IiSjGRPDv_!!6000000007639-2-tps-1080-1600.png_.webp',
        },
        {
            id: 3,
            title: 'Community Leaders & Influencers',
            description: 'Temui MVP Alibaba Cloud, ketua komunitas, dan influencer AI di seluruh dunia.',
            thumbnail: 'https://img.alicdn.com/imgextra/i3/O1CN01hTdd6k1CgQTzw55I1_!!6000000000110-2-tps-1080-1600.png_.webp',
        },
        {
            id: 4,
            title: 'Developer Showcases',
            description: 'Dengarkan cerita dari para pengembang yang memanfaatkan layanan Alibaba Cloud untuk menciptakan aplikasi inovatif.',
            thumbnail: 'https://img.alicdn.com/imgextra/i3/O1CN01JGgkYy1oOWOfRMw0y_!!6000000005215-0-tps-1080-1600.jpg_.webp',
        },
        {
            id: 5,
            title: 'Open-Source Projects',
            description: 'Pelajari proyek open-source dan komunitas yang diprakarsai dan diikuti oleh Alibaba.',
            thumbnail: 'https://img.alicdn.com/imgextra/i4/O1CN010HMthK1X6LCyCg0Ko_!!6000000002874-2-tps-1080-1600.png_.webp',
        },
        {
            id: 6,
            title: 'Keynote Speeches',
            description: 'Dapatkan inspirasi dari para ahli yang belajar tentang teknologi yang sedang tren di bidang AI.',
            thumbnail: 'https://img.alicdn.com/imgextra/i1/O1CN01ifBkll291ibqQ22bC_!!6000000008008-2-tps-1080-1600.png_.webp',
        },
        {
            id: 7,
            title: 'Products & Programs Launch',
            description: 'Dapatkan pembaruan terkini tentang rangkaian produk AI dan cloud kami yang terus berkembang.',
            thumbnail: 'https://img.alicdn.com/imgextra/i2/O1CN01ZCk7uc26IiSjGRPDv_!!6000000007639-2-tps-1080-1600.png_.webp',
        },
        {
            id: 8,
            title: 'Community Leaders & Influencers',
            description: 'Temui MVP Alibaba Cloud, ketua komunitas, dan influencer AI di seluruh dunia.',
            thumbnail: 'https://img.alicdn.com/imgextra/i3/O1CN01hTdd6k1CgQTzw55I1_!!6000000000110-2-tps-1080-1600.png_.webp',
        },
        {
            id: 9,
            title: 'Developer Showcases',
            description: 'Dengarkan cerita dari para pengembang yang memanfaatkan layanan Alibaba Cloud untuk menciptakan aplikasi inovatif.',
            thumbnail: 'https://img.alicdn.com/imgextra/i3/O1CN01JGgkYy1oOWOfRMw0y_!!6000000005215-0-tps-1080-1600.jpg_.webp',
        },
        {
            id: 10,
            title: 'Open-Source Projects',
            description: 'Pelajari proyek open-source dan komunitas yang diprakarsai dan diikuti oleh Alibaba.',
            thumbnail: 'https://img.alicdn.com/imgextra/i4/O1CN010HMthK1X6LCyCg0Ko_!!6000000002874-2-tps-1080-1600.png_.webp',
        }
    ];

    return (
        <section className="text-white py-5">
            <div className="container px-4 px-lg-5">
                <h3 className="h2 fw-bolder mb-5">Event Highlights</h3>
            </div>
            <div className="container-fluid px-0 overflow-hidden">
                <Swiper
                    loop={true}
                    centeredSlides={true}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {events.map((event) => (
                        <SwiperSlide key={event.id}>
                            <CardFeaturedEvent
                                title={event.title}
                                description={event.description}
                                thumbnail={event.thumbnail}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default SectionFeaturedEvents;
