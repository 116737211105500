import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../global/Modal/Modal";

const SectionOverview = () => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const modalElement = document.getElementById('modal-overview');

        const handleShow = () => {
            // Set the iframe src to autoplay the video when the modal is shown
            iframeRef.current.src = "https://video-intl.alicdn.com/2022/2022alibabacloudsummit/TechforInnovation.mp4";
        };

        const handleHide = () => {
            // Remove the iframe src to stop the video when the modal is closed
            iframeRef.current.src = "";
        };

        // Attach event listeners for the modal
        modalElement.addEventListener('shown.bs.modal', handleShow);
        modalElement.addEventListener('hidden.bs.modal', handleHide);

        // Cleanup event listeners on component unmount
        return () => {
            modalElement.removeEventListener('shown.bs.modal', handleShow);
            modalElement.removeEventListener('hidden.bs.modal', handleHide);
        };
    }, []);

    return (
        <>
            <section className="text-white py-5">
                <div className="container p-4 p-lg-5">
                    <h2 className="fw-bolder mb-5">Overview</h2>
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <p className="lh-lg">
                                Dalam lanskap teknologi yang berkembang pesat saat ini, pengembang berada di garis depan inovasi, membentuk masa depan melalui kreativitas dan keterampilan memecahkan masalah. Kolaborasi dan berbagi pengetahuan di antara pengembang akan mempercepat kemajuan dan memberdayakan organisasi untuk membuka potensi AI sepenuhnya.
                            </p>
                            <p className="lh-lg">
                                Kami dengan tulus mengundang Anda ke AI Forward - Alibaba Cloud Developer Summit 2025 di Jakarta, Indonesia pada tanggal 21 Januari 2025, sebuah pertemuan para pemimpin pemikiran, pakar, dan pengembang yang memiliki minat yang sama terhadap teknologi dan inovasi. Bergabunglah dengan kami dalam acara yang akan mendefinisikan ulang lanskap AI dan teknologi.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <div className="position-relative" data-bs-toggle="modal" data-bs-target="#modal-overview" role="button">
                                <img className="img-fluid d-block mx-auto" src="https://image.web.id/images/O1CN01Q2CSVo1eKGf5iWzan_6000000003852-0-tps-2717-1251.jpg_.webp" alt="AI Forward" />
                                <div className="position-absolute top-50 start-50 translate-middle">
                                    <button type="button" className="btn btn-outline-dark text-white rounded-pill p-0">
                                        <FontAwesomeIcon size="3x" icon={faPlayCircle} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal id="modal-overview" title="Overview" modalSize="modal-xl">
                <div className="ratio ratio-16x9">
                    <iframe ref={iframeRef} title="Overview" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                </div>
            </Modal>
        </>
    );
};

export default SectionOverview;
