const SectionSponsors = () => {
    const communities = [
        '/assets/img/sponsors/sponsor-24.avif',
        '/assets/img/sponsors/sponsor-7.avif',
        '/assets/img/sponsors/sponsor-20.avif',
        '/assets/img/sponsors/sponsor-5.avif',
        '/assets/img/sponsors/sponsor-6.avif',
        '/assets/img/sponsors/sponsor-8.avif',
        'https://image.web.id/images/imagedaca55d05baf674d.png',
        '/assets/img/sponsors/sponsor-9.avif',
        '/assets/img/sponsors/sponsor-10.avif',
        '/assets/img/sponsors/sponsor-11.avif',
        '/assets/img/sponsors/sponsor-12.avif',
        '/assets/img/sponsors/sponsor-13.avif',
        '/assets/img/sponsors/sponsor-14.avif',
        '/assets/img/sponsors/sponsor-15.avif',
        '/assets/img/sponsors/sponsor-16.avif',
        '/assets/img/sponsors/sponsor-17.avif',
        '/assets/img/sponsors/sponsor-18.avif',
        '/assets/img/sponsors/sponsor-19.avif',
        '/assets/img/sponsors/sponsor-21.avif',
        '/assets/img/sponsors/sponsor-22.avif',
        '/assets/img/sponsors/sponsor-23.avif',
    ];

    return (
        <section className="text-white text-center">
            <div className="container p-4 p-lg-5">
                <h2 className="fw-bolder">Partner Open-Source Projects and Communities</h2>

                <div className="row mt-5">
                    {communities.map((community, index) => {
                        return (
                            <div className="col-6 col-md-4 col-lg-3 p-2" key={index}>
                                <img className="img-fluid" src={community} alt={'community-' + index} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default SectionSponsors;
