const CardFeaturedEvent = ({ title, description, thumbnail }) => {
    return (
        <article className="card text-bg-dark border-0 rounded-0">
            <img src={thumbnail} className="card-img" alt={title} />
            <div className="card-img-overlay rounded-0 d-flex flex-column justify-content-end p-0 overflow-hidden">
                <div className="p-3" style={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))' }}>
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text small">{description}</p>
                </div>
            </div>
        </article>
    );
};

export default CardFeaturedEvent;
