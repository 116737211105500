// import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Hero.module.scss";

const Hero = () => {
    return (
        <section className={`text-white text-center ${styles.hero}`}>
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-center">
                    <div className="col-lg-10 my-auto">
                        <img className="img-fluid h-25 mb-5" src="/assets/img/logo-alibaba-cp.png" alt="Alibaba CP" />
                        <img className="img-fluid mb-5 w-75 mb-0" src="https://image.web.id/images/image15fc98d7102751ac.png" alt="AI Forward" />
                        <h1 className="title lh-lg mb-4 display-6" style={{ letterSpacing: '5px' }}>2025 Alibaba Cloud Developer Summit</h1>
                        <hr className="w-50 d-block mx-auto opacity-100 py-2" />
                        <p className="text-uppercase fw-bold">21 Januari 2025 | Raffles Hotel Jakarta, Indonesia</p>
                        <div className="d-grid gap-2 d-xl-flex my-4 justify-content-center">
                            <a href="https://s.id/aiforward2025" className="btn btn-secondary text-white rounded-1 px-5 py-2 disabled">
                                Registration Closed
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
