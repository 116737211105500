import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SectionCTA = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5">
                <div className="card text-center" style={{
                    background: `url('https://image.web.id/images/imaged14e3ba77ecdcb49.png')`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '15px'
                }}>
                    <div className="card-body" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                        <p className="display-5 fw-bolder text-white">Get a Seat Now</p>
                        <p className="lh-lg text-white">Jangan lewatkan acara seru ini dan ajak teman-temanmu untuk ikut berpartisipasi!</p>
                        <div className="d-grid gap-2 d-xl-flex my-4 justify-content-center">
                            <a href="https://s.id/aiforward2025" className="btn btn-secondary text-white rounded-1 px-3 py-2 disabled">
                                Registration Closed
                            </a>
                            <a className="btn btn-outline-light rounded-1 px-3 py-2" href="https://chat.whatsapp.com/LJKPGuzxPvPHgVBQSHZI8E" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Any Question?
                            </a>
                        </div>
                    </div>
                </div>

                <div className="text-center my-5">
                    <p className="text-white">Dipersembahkan oleh:</p>
                    <img className="img-fluid" src="/assets/img/logo-alibaba-cp.png" alt="Alibaba CP" />
                </div>
            </div>
        </section>
    )
}

export default SectionCTA