// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import styles from './Navbar.module.scss';
// import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <nav className={`navbar navbar-dark navbar-expand-lg fixed-top ${styles.navbar}`}>
            <div className="container py-2 px-4 px-lg-5">
                <HashLink className="navbar-brand" to="/#top">
                    <img className={styles.img_logo} src="https://image.web.id/images/image98913177e29e81e7.png" alt="Alibabacloud" />
                </HashLink>
                <button className="navbar-toggler shadow-0 border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <Hamburger color="#fff" size={23} toggled={isOpen} toggle={setOpen} title="menu" />
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto my-2 mb-lg-0">
                        <li className="nav-item d-grid gap-2 d-lg-flex">
                            <a className="btn btn-secondary text-white rounded-1 px-3 py-2 disabled" href="https://s.id/aiforward2025" target="_blank" rel="noopener noreferrer">
                                Registration Closed
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
